<template>
  <TepmplateBlock mod-class content-class="" title-page="Создание пользователя">
    <div class="scroll-container">
      <vuescroll>
        <div class="create-user">
          <div
            class="d-flex justify-content-center mb-5 mt-5"
            v-if="isShowSpinner"
          >
            <b-spinner />
          </div>

          <form @submit.prevent="onSubmit" v-else>
            <div class="create-user__img-load-wrap">
              <label class="file-select">
                <div
                  v-if="src === null"
                  ref="preview"
                  class="select-button"
                ></div>
                <img
                  v-else
                  class="preview-image"
                  :src="this.src ? this.src : user.profile_picture"
                  alt=""
                />
                <!--          <span v-if="value">{{ value.name }}</span>-->
                <!--          <span v-else>Загрузите фотографию устройства</span>-->
                <input type="file" @change="handleFileChange" />
              </label>
            </div>

            <CustomInput v-model="user.first_name" label="Имя" />
            <CustomInput v-model="user.last_name" label="Фамилия" />

            <CustomInput
              v-model="user.email"
              name="email"
              @change="onInputChange"
              label="E-mail"
            >
              <template #popover>
                <help-popover
                  message="В дальнейшем почта это логин. На эту почту будут приходить оповещения."
                />
              </template>
            </CustomInput>
            <span v-if="getErrors.email" class="error">{{
              getErrors.email
            }}</span>

            <FormSelect
              @chosen="setOffice"
              :options="officeOptions"
              title="Офис"
            >
              <template #title-append>
                <help-popover
                  message="Прикрепление сотрудника к конкретному офису(удаленке и тд). Если в списке нет
                 подходящего варианта - добавьте его в профиле компании."
                />
              </template>
            </FormSelect>

            <FormSelect
              @chosen="setRole"
              :options="rolesOptions"
              title="Роль"
            />
            <span v-if="getErrors.role !== undefined" class="error">{{
              getErrors.role
            }}</span>
            <h3 class="create-user__legend">
              Тип
              <help-popover message="Доступ сотрудника к следующим разделам" />
            </h3>
            <div class="create-user__radio-wrap">
              <div class="create-user__checkbox-wrap">
                <Checkbox
                  @change="onChange('mytech')"
                  name="radio-1"
                  :value="user.access.mytech"
                  :checked="user.access.mytech"
                  label="Техника"
                />
              </div>
              <div class="create-user__checkbox-wrap">
                <Checkbox
                  @change="onChange('myrequests')"
                  name="radio-2"
                  :value="options.value"
                  :checked="user.access.myrequests"
                  label="Задачи"
                />
              </div>
              <div class="create-user__checkbox-wrap">
                <Checkbox
                  @change="onChange('mycalendar')"
                  name="radio-3"
                  :value="options.value"
                  :checked="user.access.mycalendar"
                  label="Календарь"
                />
              </div>
              <div class="create-user__checkbox-wrap">
                <Checkbox
                  @change="onChange('qrcode')"
                  name="radio-4"
                  :value="false"
                  :checked="user.access.qrcode"
                  label="QR код"
                />
              </div>
              <div class="create-user__checkbox-wrap">
                <Checkbox
                  @change="onChange('myaccount')"
                  name="radio-5"
                  :value="options.value"
                  :checked="user.access.myaccount"
                  label="Счет"
                />
              </div>
            </div>

            <div class="create-user__btn-group">
              <!-- <router-link
                type="button"
                to="/admin-profile"
                class="btn btn-primary create-user__btn-prev"
              >
                Назад
              </router-link> -->

              <span
                class="btn btn-primary create-user__btn-prev"
                @click="onGoBack"
              >
                Назад
              </span>

              <button type="submit" class="btn btn-second">
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </vuescroll>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import FormSelect from "../../components/Form/FormSelect";
import CustomInput from "../../components/Form/FormElement/Input";
import Checkbox from "../../components/Form/FormElement/Checkbox";
import { mapGetters, mapActions } from "vuex";
import RequestManager from "../../function/request/RequestManager";
import {
  CLEAR_SINGLE_ERROR,
  GET_ALL_OFFICES,
  GET_USER_ROLES
} from "../../store/types";
import vuescroll from "vuescroll";
import HelpPopover from "../../components/HelpPopover";
import { OfficeApi, UserApi } from "../../function/request/apiV2";
export default {
  name: "CreateUser",
  components: {
    HelpPopover,
    Checkbox,
    FormSelect,
    CustomInput,
    TepmplateBlock,
    vuescroll
  },
  computed: {
    ...mapGetters(["officeList", "userRoles", "getErrors"]),
    officeOptions() {
      if (this.officesInfo.count) {
        return this.officesInfo.items.map(office => {
          return { text: office.name, value: office.id };
        });
      } else return [];
    },
    rolesOptions() {
      const options = [];
      if (Object.keys(this.userRoles).length > 0) {
        for (let key in this.userRoles) {
          options.push({
            text: this.userRoles[key] === "client_admin" ? "Админ" : "Менеджер",
            value: key
          });
        }
      }
      return options;
    }
  },

  data() {
    return {
      selected: "Админ",
      src: null,
      isShowSpinner: false,
      officesInfo: [],
      officeApi: new OfficeApi(),
      userApi: new UserApi(),
      user: {
        first_name: "",
        last_name: "",
        company: "",
        office: "",
        profile_picture: "",
        email: "",
        role: "",
        access: {
          mytech: false,
          qrcode: false,
          myaccount: false,
          mycalendar: false,
          myrequests: false
        }
      },
      options: [
        { text: "Админ", value: "Админ" },
        { text: "Менеджер", value: "Менеджер" }
      ]
    };
  },
  async created() {
    this.user.company = this.$route.params.companyID;

    const itemsOffices = await this.officeApi.api.listOffice({
      companyIds: [this.$route.params.companyID],
      limit: 1000000,
      sort: "name"
    });
    this.officesInfo = itemsOffices;
    console.log("CreateUser.created.officesInfo=" + this.officesInfo);

    // RequestManager()
    //   .getMainCompanyInfo()
    //   .then(data => {
    //     this.user.company = data.id;
    //   });
  },
  mounted() {
    this.GET_ALL_OFFICES();
    this.GET_USER_ROLES();
  },
  methods: {
    ...mapActions({ CLEAR_SINGLE_ERROR, GET_ALL_OFFICES, GET_USER_ROLES }),
    onInputChange(field) {
      this.CLEAR_SINGLE_ERROR(field);
    },
    onGoBack() {
      this.$router.go(-1);
    },
    handleFileChange(e) {
      const src = URL.createObjectURL(e.target.files[0]);
      this.user.profile_picture = e.target.files[0];
      this.src = src;
    },
    setOffice(value) {
      this.user.office = value;
    },
    setRole(value) {
      if (this.getErrors !== null && this.getErrors.role !== undefined) {
        this.CLEAR_SINGLE_ERROR("role");
      }
      this.user.role = value;
    },
    onChange(field) {
      this.user.access[field] = !this.user.access[field];
    },
    onSubmit() {
      console.log("create user submit and company is = " + this.user.company);
      if (this.user.profile_picture.lenght === 0) {
        delete this.user.profile_picture;
      }
      const formData = new FormData();
      for (let key in this.user) {
        if (key === "access") {
          formData.append(key, JSON.stringify(this.user[key]));
        } else {
          formData.append(key, this.user[key]);
        }
      }
      this.isShowSpinner = true;
      RequestManager()
        .createNewUser(formData)
        .then(() => {
          if (Object.keys(this.getErrors).length === 0) {
            this.$router.push({
              name: "AdminProfile",
              params: { mode: "createUser" }
            });
          }
        })
        .finally(() => (this.isShowSpinner = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-container {
  margin: 0 auto;
  overflow: hidden;
  // max-height: 645px;
}
.error {
  position: relative;
  top: -10px;
  font-size: 12px;
  color: red;
}
.preview-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  width: 97px;
  height: 97px;
}
.create-user {
  margin: 0 auto;
  padding: 38px 72px 36px;
  width: 100%;
  max-width: 498px;
  min-height: 672px;
  // max-height: 95vh;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
}

.form-group__label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;

  color: var(--main-text-color) !important;
}

.create-user__img-load-wrap {
  margin-bottom: 30px;
}

.create-user__btn-group {
  display: flex;
  align-items: center;
}

.create-user__btn {
  max-width: 100%;
}

.create-user__btn-prev {
  border: 1px solid #006b90;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.01));
  margin-right: 7px;
}

.file-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 14px;

  color: #1f1f1f;
}

.file-select > .select-button {
  background-image: url("../../resource/img/edit-ava.svg");
  background-repeat: no-repeat;
  font-size: 10px;
  width: 97px;
  height: 97px;
  cursor: pointer;
  margin-right: 17px;
}

.file-select > input[type="file"] {
  display: none;
}

.create-user__radio-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
  //justify-content: space-between;
}

.create-user__label-wrap {
  width: 50%;
  &:not(:last-child) {
    margin-bottom: 27px;
  }
}

.create-user__checkbox-wrap {
  width: 50%;

  &:not(:last-child) {
    margin-bottom: 27px;
  }
}

//.create-user__label {
//  margin-left: 16px;
//  font-weight: 500;
//  font-size: 14px;
//  line-height: 16px;
//
//  color: #4B4B67;
//}

.create-user__legend {
  margin-top: 32px;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}
</style>
