<template>
  <label class="checkbox-label">
    <input
      ref="checkbox"
      type="checkbox"
      :name="name"
      class="checkbox-label__checkbox"
      :value="value"
      :checked="checked"
      @change="onChange"
    />

    <span class="checkbox-label__text mr-3">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    name: String,
    value: Boolean,
    label: String,
    checked: Boolean
  },
  methods: {
    onChange(e) {
      this.$emit("change");
      this.$emit("checked", {
        value: e.target.name,
        checked: this.$refs.checkbox.checked
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-label {
  //width: 50%;
  &:not(:last-child) {
    margin-bottom: 27px;
  }
}

.checkbox-label__checkbox {
  display: none;
}

.checkbox-label__text {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;

  &::before {
    content: "";
    display: inline-block;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: #ffffff;
    border: 2px solid #006b90;
    border-radius: 4px;
  }
}

.checkbox-label__checkbox:checked + .checkbox-label__text::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  background-color: #006b90;
  z-index: 2;
}

.checkbox-label__checkbox:checked + .checkbox-label__text::after {
  content: "✓";
  position: absolute;
  left: 6px;
  z-index: 2;
  color: #ffffff;
  top: -2px;
}

/* Hover */

.checkbox-label__text:hover::before {
  filter: brightness(120%);
}

/* Disabled */

.checkbox-label__checkbox:disabled + .checkbox-label__text::before {
  filter: grayscale(100%);
}
</style>
